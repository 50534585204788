.user-profile-page {
  background-color: rgb(245, 250, 255);
}
.Mui-error {
  margin-left: 0px !important;
}
/* sidebar overrider */
.MuiListItem-root span {
  font-size: 16px;
}
.MuiListItem-root a {
  text-decoration: none;
  color: #000;
}